import React from "react";
import Title from "../../markdown/Title";
import Text from "../../markdown/Text";
import Link from "../../markdown/Link";
import {List} from "../../news/body/components/List";
import * as styles from "../../../styles/pages/faq.module.scss";

const faqMarkdown = {
    h1: Title,
    h2: Title,
    h3: Title,
    h4: Title,
    h5: Title,
    h6: Title,
    p: Text,
    a: ({...props}: any) => <Link isUnderline {...props}/>,
    ol: ({...props}: any) => <List className={styles.list} {...props}/>,
    ul: ({...props}: any) => <List className={styles.list} {...props}/>,
}

export default faqMarkdown