// extracted by mini-css-extract-plugin
export var accentColor = "#e10600";
export var content = "accordion-module--content--cbfbe";
export var dividerColor = "#e4e4e4";
export var dividerWhiteColor = "#fff";
export var header = "accordion-module--header--4164c";
export var icon = "accordion-module--icon--bf82c";
export var mobileIcon = "accordion-module--mobileIcon--b53d9";
export var mobileIconContainer = "accordion-module--mobileIconContainer--6d9d9";
export var open = "accordion-module--open--ee655";
export var primaryColor = "#fff";
export var root = "accordion-module--root--36843";
export var secondaryColor = "#000";
export var textDisabledColor = "#ccc";
export var textPrimaryColor = "#101820";
export var textSecondaryColor = "#fff";