import React, {useRef, useState} from "react";
import * as styles from "../../styles/components/accordion.module.scss"
import StyledText, {TextStyles} from "../typography";
import {IconArrowDown} from "../svg";
import ReactMarkdown from "react-markdown";
import faqMarkdown from "../faq/markdown";

type AccordionProps = {
    label: string | null,
    description: string | null,
}

export const Accordion = ({label, description}: AccordionProps) => {
    const [open, setOpen] = useState(false);
    const contentEl = useRef<HTMLDivElement>(null);

    return (
        <div className={`${styles.root} ${open ? styles.open : ''}`}>
            <div className={styles.header} onClick={() => setOpen(!open)}>
                <StyledText variant={TextStyles.h3}>
                    {label}
                </StyledText>
                <div>
                    <IconArrowDown className={styles.icon}/>
                </div>
            </div>
            <div
                ref={contentEl}
                className={styles.content}
                style={open && contentEl.current ? {height: contentEl.current.scrollHeight } : {height: 0}}
            >
                <ReactMarkdown
                    components={faqMarkdown}
                    skipHtml
                >
                    {description || ''}
                </ReactMarkdown>
            </div>
            <div className={styles.mobileIconContainer} onClick={() => setOpen(!open)}>
                <IconArrowDown className={`${styles.icon} ${styles.mobileIcon}`}/>
            </div>
        </div>
    )
}