import React from "react";
import {Layout} from "../components/Layout";
import {GetServerDataProps, GetServerDataReturn} from "gatsby";
import StrapiClient, {StrapiResponse, StrapiSingleTypes} from "../strapi-client/strapi-client";
import * as styles from "../styles/pages/faq.module.scss";
import StyledText, {TextStyles} from "../components/typography";
import {MenuModel} from "../store/models/menu";
import {FaqPageModel} from "../store/models/faq-page";
import {Accordion} from "../components/common/Accordion";
import SEO from "../components/SEO";

type FAQPageProps = {
    serverData: {
        menu: StrapiResponse,
        page: StrapiResponse,
        successPage: StrapiResponse
    },
    location: any
}

const FAQPage = (props: FAQPageProps) => {
    const faqModel: FaqPageModel = FaqPageModel.fromJSON(props.serverData.page);
    const menuModel: MenuModel = MenuModel.fromJSON(props.serverData.menu);
    return (
        <>
            <SEO seo={faqModel.seo}></SEO>
            <Layout menu={menuModel}>
                <div className={styles.grid}>
                    <StyledText variant={TextStyles.h1} tag='h1' className={styles.title}>
                        {faqModel.title}
                    </StyledText>
                    <div>
                        {faqModel.accordion?.map(item => (
                            <Accordion label={item.label} description={item.description}/>
                        ))}
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default FAQPage;

export async function getServerData(context: GetServerDataProps): GetServerDataReturn {
    try {
        const locale: string = context.pageContext.locale as string;
        const strapi = new StrapiClient(locale);
        const page = await strapi.getSingleType(StrapiSingleTypes.FAQPage);
        const menu = await strapi.getSingleType(StrapiSingleTypes.menu);
        return {
            props: {
                page,
                menu,
            },
        }
    } catch (error) {
        return {
            status: 500,
            headers: {},
            props: {}
        }
    }
}