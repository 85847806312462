import {SEOModel} from "./seo";

export class AccordionModel {
    constructor(
       public readonly label: string | null,
       public readonly description: string | null
    ) {}

    static fromJSON(json: any) {
        return new AccordionModel(
            json['label'],
            json['description'],
        )
    }
}


export class FaqPageModel {
    constructor(
       public readonly title: string | null,
       public readonly accordion: AccordionModel[] | null,
       public readonly seo: SEOModel | null,
    ) {}

    static fromJSON(json: any) {
        return new FaqPageModel(
            json['title'],
            json['accordion'] ? json['accordion'].map((item: any) => AccordionModel.fromJSON(item)) : [],
            json['seo'] ? SEOModel.fromJSON(json['seo']) : null,
        )
    }
}